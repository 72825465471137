body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

body {
  background-color: black; }

.relative_container {
  position: relative;
  text-align: center;
  color: white; }

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.ptnv {
  padding-top: 96px; }

.bk_header {
  min-width: 480px;
  max-width: 720px; }

.img_project {
  border-radius: 8px; }

.bshadow {
  text-shadow: 2px 2px #000000; }

.blue {
  color: dodgerblue; }

.white {
  color: white; }

.title_card {
  font-weight: bold; }

.tc {
  text-align: center; }

.tr {
  text-align: right; }

.tm {
  vertical-align: middle; }

.fs1 {
  font-size: 1rem; }

.fs2 {
  font-size: 2rem; }

.fs3 {
  font-size: 3rem; }

.fs4 {
  font-size: 4rem; }

.fs5 {
  font-size: 5rem; }

.fs6 {
  font-size: 6rem; }

.fs7 {
  font-size: 7rem; }

.fs8 {
  font-size: 8rem; }

.fs9 {
  font-size: 9rem; }

.fs10 {
  font-size: 10rem; }

.fsp11 {
  font-size: 11px; }

.fsp12 {
  font-size: 12px; }

.fsp13 {
  font-size: 13px; }

.fsp14 {
  font-size: 14px; }

.fsp15 {
  font-size: 15px; }

.fsp16 {
  font-size: 16px; }

.fsp17 {
  font-size: 17px; }

.fsp18 {
  font-size: 18px; }

.fsp19 {
  font-size: 19px; }

.fsp20 {
  font-size: 20px; }

.fsp21 {
  font-size: 21px; }

.fsp22 {
  font-size: 22px; }

.fsp23 {
  font-size: 23px; }

.fsp24 {
  font-size: 24px; }

.p1 {
  padding: 1px; }

.p2 {
  padding: 2px; }

.p3 {
  padding: 3px; }

.p4 {
  padding: 4px; }

.p5 {
  padding: 5px; }

.p6 {
  padding: 6px; }

.p7 {
  padding: 7px; }

.p8 {
  padding: 8px; }

.p9 {
  padding: 9px; }

.p10 {
  padding: 10px; }

.p11 {
  padding: 11px; }

.p12 {
  padding: 12px; }

.p13 {
  padding: 13px; }

.p14 {
  padding: 14px; }

.p15 {
  padding: 15px; }

.p16 {
  padding: 16px; }

.p17 {
  padding: 17px; }

.p18 {
  padding: 18px; }

.p19 {
  padding: 19px; }

.p20 {
  padding: 20px; }

.pr1 {
  padding: 1rem; }

.pr2 {
  padding: 2rem; }

.pr3 {
  padding: 3rem; }

.pr4 {
  padding: 4rem; }

.mr1 {
  margin: 1rem; }

.mr2 {
  margin: 2rem; }

.mr3 {
  margin: 3rem; }

.mr4 {
  margin: 4rem; }

.mr5 {
  margin: 5rem; }

.mr6 {
  margin: 6rem; }

.mr7 {
  margin: 7rem; }

.mr8 {
  margin: 8rem; }

.mr9 {
  margin: 9rem; }

.mr10 {
  margin: 10rem; }

.mr11 {
  margin: 11rem; }

.mr12 {
  margin: 12rem; }

.mr13 {
  margin: 13rem; }

.mr14 {
  margin: 14rem; }

.mr15 {
  margin: 15rem; }

.mr16 {
  margin: 16rem; }

.mr17 {
  margin: 17rem; }

.mr18 {
  margin: 18rem; }

.mr19 {
  margin: 19rem; }

.mr20 {
  margin: 20rem; }

.m1 {
  margin: 1px; }

.m2 {
  margin: 2px; }

.m3 {
  margin: 3px; }

.m4 {
  margin: 4px; }

.m5 {
  margin: 5px; }

.m6 {
  margin: 6px; }

.m7 {
  margin: 7px; }

.m8 {
  margin: 8px; }

.m9 {
  margin: 9px; }

.m10 {
  margin: 10px; }

.m11 {
  margin: 11px; }

.m12 {
  margin: 12px; }

.m13 {
  margin: 13px; }

.m14 {
  margin: 14px; }

.m15 {
  margin: 15px; }

.m16 {
  margin: 16px; }

.m17 {
  margin: 17px; }

.m18 {
  margin: 18px; }

.m19 {
  margin: 19px; }

.m20 {
  margin: 20px; }

.w5 {
  width: 5%; }

.w10 {
  width: 10%; }

.w15 {
  width: 15%; }

.w20 {
  width: 20%; }

.w25 {
  width: 25%; }

.w30 {
  width: 30%; }

.w35 {
  width: 35%; }

.w40 {
  width: 40%; }

.w45 {
  width: 45%; }

.w50 {
  width: 50%; }

.w55 {
  width: 55%; }

.w60 {
  width: 60%; }

.w65 {
  width: 65%; }

.w70 {
  width: 70%; }

.w75 {
  width: 75%; }

.w80 {
  width: 80%; }

.w85 {
  width: 85%; }

.w90 {
  width: 90%; }

.w95 {
  width: 95%; }

.w100 {
  width: 100%; }

