body{
  background-color: black;
}

.relative_container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ptnv{
  padding-top: 96px;
}

.bk_header{
  min-width: 480px;
  max-width: 720px;
}

.img_project{
  border-radius: 8px;
}

.bshadow{
  text-shadow: 2px 2px #000000;
}

.blue{
  color: dodgerblue;
}

.white{
  color: white;
}

.title_card{
  font-weight: bold;
}

.tc{
  text-align: center;
}

.tr{
  text-align: right;
}

.tm{
  vertical-align: middle;
}

@for $i from 1 through 10 {
  .fs#{$i}{
    font-size: $i*1rem;
  }
}

@for $i from 11 through 24 {
  .fsp#{$i}{
    font-size: $i*1px;
  }
}

@for $i from 1 through 20 {
  .p#{$i}{
    padding: $i*1px;
  }
}

@for $i from 1 through 4 {
  .pr#{$i}{
    padding: $i*1rem;
  }
}

@for $i from 1 through 20 {
  .mr#{$i}{
    margin: $i*1rem;
  }
}

@for $i from 1 through 20 {
  .m#{$i}{
    margin: $i*1px;
  }
}

@for $i from 1 through 20 {
  .w#{5*$i}{
    width: $i*5%;
  }
}